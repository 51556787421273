<template>
	<div class="home">
		<div class="left" v-loading="loading1">

			<el-tabs v-model="activeName" type="border-card"
				style="height: 100%; box-sizing: border-box; overflow: auto;">
				<el-tab-pane label="社区" name="community">
					<div class="tree">
						<el-tree :expand-on-click-node="false" :data="tree" class="filter-tree" :props="defaultProps"
							:default-expanded-keys="[1]" :default-checked-keys="[1]" :highlight-current="true"
							@node-click="nodeClick" ref="tree">
							<span class="my-custom" slot-scope="{ node, data }" style="width: 100%;height: 100%;">
								<span style="width: 100%;height: 100%;line-height: 32px;">{{ data.name }}</span>
							</span>
						</el-tree>
					</div>
				</el-tab-pane>
				<el-tab-pane label="标签" name="label">
					<el-tree :expand-on-click-node="false" accordion :load="loadNode" @node-expand="expandClick"
						@node-click="lableTreeNodeClick" lazy :data="lableTreeData" :highlight-current="true"
						:props="labelTreeProps"></el-tree>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="right">

			<div class="transformer">
				<!-- <div class="topbox">
					
				</div> -->
				<div class="clearfix electric-select">
					<el-form ref="ruleForm" :model="chaxun" :inline="true">
						<el-form-item label="社区：">
							<el-select v-model="chaxun.shequ" placeholder="请选择社区" clearable filterable>
								<el-option v-for="(item,index) in list" :key="index" :label="item.name"
									:value="item.levelcode"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="房主姓名：">
							<el-input class="shuru" v-model="chaxun.name"></el-input>
						</el-form-item>
						<el-form-item label="电话号码：">
							<el-input class="shuru" v-model="chaxun.phone"
								oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
						</el-form-item>
						<el-form-item label="房屋性质：">
							<el-select v-model="chaxun.xingzhi" placeholder="请选择房屋性质" filterable>
								<el-option key="0" label="全部" value=""></el-option>
								<el-option key="1" label="出租" value="出租"></el-option>
								<el-option key="2" label="群租" value="群租"></el-option>
								<el-option key="3" label="空置" value="空置"></el-option>
								<el-option key="4" label="常住" value="常住"></el-option>
								<el-option key="5" label="未采集" value="未采集"></el-option>
								<el-option key="6" label="地下室" value="地下室"></el-option>
								<el-option key="7" label="门面房" value="门面房"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="机动车数量：">
							<el-input class="shuru" v-model="chaxun.carnum"></el-input>
						</el-form-item>
						<el-form-item label="电瓶车数量：">
							<el-input class="shuru" v-model="chaxun.bikenum"></el-input>
						</el-form-item>
						<el-form-item label="当前居住人数大于：">
							<el-input class="shuru" v-model="chaxun.dayu"></el-input>
						</el-form-item>
						<el-form-item label="当前居住人数小于：">
							<el-input class="shuru" v-model="chaxun.xiaoyu"></el-input>
						</el-form-item>
						<el-form-item label="房屋地址：">
							<el-input class="shuru" v-model="chaxun.address"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onsearch()">
								<i class="el-icon-search"></i>
								查询
							</el-button>
							<el-button type="primary" @click="gotoGui">
								<i class="el-icon-data-analysis"></i>
								图形化展示
							</el-button>
							<el-button type="primary" :disabled="exportLoading" @click="exportList()">
								<i class="el-icon-top"></i>
								导出
							</el-button>
						</el-form-item>
					</el-form>

				</div>
				<div v-loading="loading2">
					<el-table :data="tableData" border style="width: 100%;">
						<el-table-column type="index" width="70" align="center" height show-overflow-tooltip>
							<template scope="scope"><span>{{scope.$index+(currentPage - 1) * fenye.pagesize + 1}}
								</span></template>
						</el-table-column>
						<el-table-column prop="mastername" label="房主姓名" width="120" align="center" height
							show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="phone" label="联系电话" width="180" align="center" height
							show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="name" label="社区" width="140" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="housename" label="居住地址" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="livenum" label="居住人数" width="100" align="center" height
							show-overflow-tooltip>
						</el-table-column>
						<el-table-column label="居住情况" width="120" align="center" height show-overflow-tooltip>
							<template slot-scope="scope">
								<span v-if="scope.row.house_type == '未采集'"
									style="color: #ee4c4c;">{{scope.row.house_type}}</span>
								<span v-else-if="scope.row.house_type == '出租'"
									style="color: #12b887;">{{scope.row.house_type}}</span>
								<span v-else-if="scope.row.house_type == '群租'"
									style="color: #08c0d0;">{{scope.row.house_type}}</span>
								<span v-else-if="scope.row.house_type == '空置'"
									style="color: #ed9100;">{{scope.row.house_type}}</span>
								<span v-else style="color: #5284f7;">{{scope.row.house_type}}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="300" align="center" height show-overflow-tooltip>
							<template slot-scope="scope">
								<el-button type="primary" size="small"
									@click="lookHouseInManClick(scope.row.house_id)">查看</el-button>
								<el-button type="primary" size="small"
									@click="relation(scope.row.house_id)">同住人员关系图</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum"></el-pagination>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="同住人员关系图" width='1600px' :visible.sync="xiangqing2" :close-on-click-modal="false"
			:before-close="handleClose2">
			<div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
				<div style="position: relative;width: 50%;">
					<div class="box" id="box" style="height: 600px;position: relative;">
					
					</div>
					<div class="tishi">
						<div class="type1">颜色一致代表户号一致</div>
						<div class="type2 circle">户籍人口</div>
						<div class="type2 rect">常住人口</div>
						<div class="type2 triangle">租客</div>
						<div class="type2 roundrect">户在人不在</div>
					</div>
				</div>
				<div class="relationbox">
					<div class="relationlist" v-for="item in peoplelist" :key="item.personcard">
						<div class="headsex">
							<div class="iconsex">
								<img v-if="item.person_sex=='女'" src="@/assets/imgs/woman.png" alt="女">
								<img v-else src="@/assets/imgs/man.png" alt="男">
							</div>
						</div>
						<div :class="selectname == item.personname?'selected xiangxi':'xiangxi'">
							<p><span>姓名：</span>{{ item.personname}}</p>
							<p><span>电话：</span>{{ item.personphone}}</p>
							<p><span>身份证号：</span>{{ item.personcard }}</p>
							<div class="biaoqian" v-if="item.labellist.length>0">
								<div class="name">标签：</div>
								<div class="tag" v-for="(tag,index) in item.labellist" :key="index">{{tag.label_name}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</el-dialog>
		<el-dialog title="查看详情" width='75%' :visible.sync="xiangqing3" :close-on-click-modal="false"
			:before-close="handleClose3">
			<div class="create" style="overflow: auto;">
				<p>基础信息</p>
				<div class="produce">
					<div>姓名：{{jichu.mulpersoninfp.personname}}</div>
					<div>性别：{{jichu.mulpersoninfp.person_sex}}</div>
					<div>身份证：{{jichu.mulpersoninfp.personcard}}</div>
					<div>现居地：{{jichu.mulpersoninfp.housearea}}</div>
					<div>户籍地：{{jichu.mulpersoninfp.censusaddr}}</div>
					<div>电话：{{jichu.mulpersoninfp.personphone}}</div>
					<div>在住情况：{{jichu.mulpersoninfp.deldesc}}</div>
					<div>社区名：{{jichu.mulpersoninfp.communityname}}</div>
					<div>小区名/组名：{{jichu.mulpersoninfp.regionname}}</div>
					<div>户口性质：{{ getTextOfVal(jichu.mulpersoninfp.incommunity) }}</div>
					<!-- <div>是否为党员：{{jichu.mulpersoninfp.party}}</div> -->
					<div class="biaoqian" v-if="jichu.labelinfo && jichu.labelinfo.length > 0">
						<ul>
							<li v-for="blab in jichu.labelinfo" :key="blab.label_item_id">{{ blab.label_name}}</li>
						</ul>
					</div>
				</div>
		
			</div>
		</el-dialog>
		<el-dialog title="查看详情" width='70%' :visible.sync="xiangqing" :close-on-click-modal="false"
			:before-close="handleClose">
			<div class="create" style="overflow: auto;">
				<div v-loading="loadingHouse" v-if="housemsg" class="detailtable">
					<p>房屋信息</p>
					<div class="labelname">住址</div>
					<div style="width: 26%;">{{housemsg.housename}}</div>
					<div class="labelname">所在社区</div>
					<div style="width: 14%;">{{housemsg.name}}</div>
					<div class="labelname">房屋性质</div>
					<div style="width: 14%;">{{housemsg.house_type}}</div>
					<div class="labelname">住房种类</div>
					<div style="width: 14%;">{{housemsg.house_type_flag}}</div>
					<div class="labelname">房屋面积</div>
					<div style="width: 26%;">{{housemsg.area}}平方米</div>
					<div class="labelname">机动车数量</div>
					<div style="width: 14%;">{{housemsg.carnum}}</div>
					<div class="labelname">电动车数量</div>
					<div style="width: 14%;">{{housemsg.bikenum}}</div>
					<div class="labelname">宠物类型</div>
					<div style="width: 14%;">{{housemsg.pettype}}</div>
				</div>
				<div v-loading="loadingHouse" v-else class="detailtable">
					<p>房屋信息</p>
					<div style="width: 100%; text-align: left;">暂无</div>
				</div>

				<div style="padding: 20px; display: flex; justify-content: flex-end;">
					<el-radio-group v-model="showType">
					    <el-radio :label="1">户型展示</el-radio>
					    <el-radio :label="2">表格展示</el-radio>
					    <el-radio :label="3">关系图展示</el-radio>
					  </el-radio-group>
				</div>

				<div class="personsbox" v-if="showType == 3">
					<div class="huzhuinfo">
						<div class="fz_item" v-if="masterlist[0]">
							<div class="head">
								<div class="iconsex">
									<img v-if="masterlist[0].person_sex=='女'" src="@/assets/imgs/woman.png" alt="女">
									<img v-else src="@/assets/imgs/man.png" alt="男">
								</div>
								<img src="@/assets/imgs/fangzhu.png" alt="房主">
							</div>
							<ul>
								<li><span>姓名：</span>{{ masterlist[0].mastername}}</li>
								<li><span>电话：</span>{{ masterlist[0].phone}}</li>
								<li><span>身份证号：</span>{{ masterlist[0].personcard }}</li>
							</ul>
						</div>
						<div class="fz_item" v-else>
							<div class="head">
								<i class="el-icon-user-solid"></i>
								<img src="@/assets/imgs/fangzhu.png" alt="房主">
							</div>
							<div>暂无房主信息</div>
						</div>
					</div>

					<div class="zhuhuinfo">
						<div class="zh_item" v-for="item in personlist" :key="item.personcard">
							<div class="head">
								<div class="iconsex">
									<img v-if="item.person_sex=='女'" src="@/assets/imgs/woman.png" alt="女">
									<img v-else src="@/assets/imgs/man.png" alt="男">
								</div>
								<p v-if="item.housemasterrelation == '租客'">流动人口</p>
								<p v-else>常住人口</p>
							</div>
							<ul>
								<li><span>姓名：</span>{{ item.personname}}</li>
								<li><span>电话：</span>{{ item.personphone}}</li>
								<li><span>身份证号：</span>{{ item.personcard }}</li>
								<li><span>与房主关系：</span> {{ item.housemasterrelation }}</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="tabletype" v-if="showType == 2">
					<div v-loading="loadingHouse" class="detailtable" v-for="(item,index) in masterlist" :key="index">
						<p>房主基本情况</p>
						<div class="labelname">姓名</div>
						<div style="width: 13.4%;">{{item.mastername}}</div>
						<div class="labelname">手机号</div>
						<div style="width: 34.8%;">{{item.phone}}</div>
						<div class="labelname">身份证号</div>
						<div style="width: 27.8%;">{{item.personcard}}</div>
						<div class="labelname">居住地址</div>
						<div style="width: 92%;" class="biaoshi">{{item.address}}</div>
					</div>
					<div v-loading="loadingHouse" class="detailtable" v-for="item in personlist" :key="item.personcard">
						<p>居民基本情况</p>
						<div class="labelname">姓名</div>
						<div style="width: 17%;">{{item.personname}}</div>
						<div class="labelname">民族</div>
						<div style="width: 17%;">{{item.personnation}}</div>
						<div class="labelname">户籍地址</div>
						<div style="width: 42%;">{{item.censusaddr}}</div>
						<div class="labelname">性别</div>
						<div style="width: 17%;">{{item.person_sex}}</div>
						<div class="labelname">学历</div>
						<div style="width: 17%;">{{item.eduid}}</div>
						
						<div class="labelname">身份证号</div>
						<div style="width: 17%;">{{item.personcard}}</div>
						<div class="labelname">出生日期</div>
						<div style="width: 17%;">{{item.personbirthday}}</div>
						<div class="labelname">婚姻状况</div>
						<div style="width: 17%;">{{item.marriage}}</div>
						<div class="labelname">宗教信仰</div>
						<div style="width: 17%;">{{item.faith}}</div>
						<div class="labelname">联系方式</div>
						<div style="width: 17%;">{{item.personphone}}</div>
						<div class="labelname">人员类别</div>
						<div style="width: 17%;">
							<span>{{ getTextOfVal(item.incommunity) }}</span>
						</div>
						<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;" v-if="item.incommunity == 0">
							<div class="labelname">与房主关系</div>
							<div style="width: 17%;">{{item.housemasterrelation}}</div>
							<div class="labelname">户号</div>
							<div style="width: 17%;">{{item.accountcode}}</div>
							<div class="labelname">与户主关系</div>
							<div style="width: 17%;">{{item.accountrelation}}</div>
							<div class="labelname">是否有居住证</div>
							<div style="width: 17%;">{{item.temporarycard}}</div>
						</div>
						<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;" v-if="item.incommunity == 1">
							<div class="labelname">与房主关系</div>
							<div style="width: 42%;">{{item.housemasterrelation}}</div>
							<div class="labelname">是否有居住证</div>
							<div style="width: 42%;">{{item.temporarycard}}</div>
						</div>
						<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;" v-if="item.incommunity == 2">
							<div class="labelname">户号</div>
							<div style="width: 42%;">{{item.accountcode}}</div>
							<div class="labelname">与户主关系</div>
							<div style="width: 42%;">{{item.accountrelation}}</div>
						</div>
						<div class="biaoqian" style="width: 100%;" v-if="item.labellist.length > 0">
							<ul>
								<li v-for="blab in item.labellist" :key="blab.label_item_id">{{ blab.label_name}}</li>
							</ul>
						</div>
					</div>
					
				</div>
				<div class="tabletype" v-if="showType == 1">
					<div class="alldata">
						<div class="hang one">
							<div>全部{{showpicpage.personnums}}人</div>
							<div>户籍人口{{showpicpage.huzairenbujingchangzai + showpicpage.huzairenbuzai + showpicpage.huzairenzai}}人 户数{{showpicpage.hushu}}户</div>
							<div>流动人口{{showpicpage.liudongrenkou}}人 户数{{showpicpage.liudonghushu}}户</div>
							<div v-if="housemsg.roomallnums && housemsg.roomallnums>0">总房间数{{housemsg.roomallnums}}间 空余数{{housemsg.roomallnums - showpicpage.liudongrenkou}}间</div>
						</div>
						<div class="hang two">
							<div @click="xuanze(0)" :class="selectleixing === 0?'selected':''">人在户在{{showpicpage.huzairenzai}}人</div>
							<div @click="xuanze(3)" :class="selectleixing === 3?'selected':''">户在人不在{{showpicpage.huzairenbuzai}}人</div>
							<div @click="xuanze(1)" :class="selectleixing === 1?'selected':''">户在人不经常在{{showpicpage.huzairenbujingchangzai}}人</div>
							<div @click="xuanze(2)" :class="selectleixing === 2?'selected':''">流动人口{{showpicpage.liudongrenkou}}人</div>
						</div>
						<div class="hang three">
							<div v-for="(item,index) in showpicpage.housepersonlable" :class="selectTagName === item.label_name?'highblue':''" :key="index" @click="highTag(item.label_name)">{{item.label_name}} {{item.labelnums}}人</div>
						</div>
					</div>
					<div class="allpeople">
						<div class="yihu" v-for="(item,index) in showpicpage.personinfolist" :key="index">
							<div class="title"> <img src="../../assets/imgs/houseicon.png" alt=""> <p>{{ item[0]? item[0].accountcode : '/'}}</p></div>
							<div class="people" v-for="(item2,index2) in item" :key="index+index2">
								<div class="head" @click="lookClick(item2.id)">
									<div :class="item2.incommunity === selectleixing?'img lanse':'img huise'">
										<img v-if="item2.person_sex == '男' && item2.age < 18" src="../../assets/imgs/person/son.png" alt="">
										<img v-if="item2.person_sex == '女' && item2.age < 18" src="../../assets/imgs/person/girl.png" alt="">
										<img v-if="item2.person_sex == '男' && item2.age >= 18 && item2.age < 60" src="../../assets/imgs/person/father.png" alt="">
										<img v-if="item2.person_sex == '女' && item2.age >= 18 && item2.age < 60" src="../../assets/imgs/person/mother.png" alt="">
										<img v-if="item2.person_sex == '男' && item2.age >= 60" src="../../assets/imgs/person/grandfather.png" alt="">
										<img v-if="item2.person_sex == '女' && item2.age >= 60" src="../../assets/imgs/person/grandmother.png" alt="">
									</div>
									<p>{{item2.personname}}</p>
									<p v-if="item2.age" style="font-size:12px; line-height: 1em;">（{{ item2.age }}岁）</p>
									<div class="tag" v-for="(item3,index3) in item2.lablelist" :key="index+index2+index3"  :class="item3.label_name === selectTagName? 'blue': ''">{{item3.label_name}}</div>
								</div>
								
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- <el-dialog title="查看详情" width='90%' :visible.sync="xiangqing" :close-on-click-modal="false"
			:before-close="handleClose">
			<div v-loading="loading3" class="create" style="overflow: auto;">
				<div class="detailtable">
					<p>房屋信息</p>
					<div class="labelname">住址</div>
					<div style="width: 26%;">{{housemsg.housename}}</div>
					<div class="labelname">所在社区</div>
					<div style="width: 14%;">{{housemsg.name}}</div>
					<div class="labelname">房屋性质</div>
					<div style="width: 14%;">{{housemsg.house_type}}</div>
					<div class="labelname">住房种类</div>
					<div style="width: 14%;">{{housemsg.house_type_flag}}</div>
					<div class="labelname">房屋面积</div>
					<div style="width: 26%;">{{housemsg.area}}平方米</div>
					<div class="labelname">机动车数量</div>
					<div style="width: 14%;">{{housemsg.carnum}}</div>
					<div class="labelname">电动车数量</div>
					<div style="width: 14%;">{{housemsg.bikenum}}</div>
					<div class="labelname">宠物类型</div>
					<div style="width: 14%;">{{housemsg.pettype}}</div>
				</div>
				<div class="detailtable" v-for="(item,index) in masterlist" :key="index">
					<p>房主基本情况</p>
					<div class="labelname">姓名</div>
					<div style="width: 13.4%;">{{item.mastername}}</div>
					<div class="labelname">手机号</div>
					<div style="width: 34.8%;">{{item.phone}}</div>
					<div class="labelname">身份证号</div>
					<div style="width: 27.8%;">{{item.personcard}}</div>
					<div class="labelname">居住地址</div>
					<div style="width: 92%;" class="biaoshi">{{item.address}}</div>
				</div>
				<div class="detailtable" v-for="(item,index) in personlist" :key="index">
					<p>居民基本情况</p>
					<div class="labelname">姓名</div>
					<div style="width: 17%;">{{item.personname}}</div>
					<div class="labelname">民族</div>
					<div style="width: 17%;">{{item.personnation}}</div>
					<div class="labelname">户籍地址</div>
					<div style="width: 42%;">{{item.censusaddr}}</div>
					<div class="labelname">性别</div>
					<div style="width: 17%;">{{item.person_sex}}</div>
					<div class="labelname">学历</div>
					<div style="width: 17%;">{{item.eduid}}</div>
					
					<div class="labelname">身份证号</div>
					<div style="width: 17%;">{{item.personcard}}</div>
					<div class="labelname">出生日期</div>
					<div style="width: 17%;">{{item.personbirthday}}</div>
					<div class="labelname">婚姻状况</div>
					<div style="width: 17%;">{{item.marriage}}</div>
					<div class="labelname">宗教信仰</div>
					<div style="width: 17%;">{{item.faith}}</div>
					<div class="labelname">联系方式</div>
					<div style="width: 17%;">{{item.personphone}}</div>
					<div class="labelname">人员类别</div>
					<div style="width: 17%;">
						<span v-if="item.incommunity == 0">户籍人口</span>
						<span v-if="item.incommunity == 1">常住人口</span>
						<span v-if="item.incommunity == 2">租客</span>
						<span v-if="item.incommunity == 3">户在人不在</span>
					</div>
					<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;" v-if="item.incommunity == 0 || item.incommunity == 3">
						<div class="labelname">与房主关系</div>
						<div style="width: 17%;">{{item.housemasterrelation}}</div>
						<div class="labelname">户号</div>
						<div style="width: 17%;">{{item.accountcode}}</div>
						<div class="labelname">与户主关系</div>
						<div style="width: 17%;">{{item.accountrelation}}</div>
						<div class="labelname">是否有居住证</div>
						<div style="width: 17%;">{{item.temporarycard}}</div>
					</div>
					<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;" v-if="item.incommunity == 1">
						<div class="labelname">与房主关系</div>
						<div style="width: 42%;">{{item.housemasterrelation}}</div>
						<div class="labelname">是否有居住证</div>
						<div style="width: 42%;">{{item.temporarycard}}</div>
					</div>
					<div style="width:100%;padding: 0 !important;border-right: none !important;border-bottom: none !important;" v-if="item.incommunity == 2">
						<div class="labelname">户号</div>
						<div style="width: 42%;">{{item.accountcode}}</div>
						<div class="labelname">与户主关系</div>
						<div style="width: 42%;">{{item.accountrelation}}</div>
					</div>
					<div class="biaoqian" style="width: 100%;" v-if="item.labellist.length > 0">
						<ul>
							<li v-for="blab in item.labellist" :key="blab.label_item_id">{{ blab.label_name}}</li>
						</ul>
					</div>
				</div>
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import * as FileSaver from 'file-saver'
	import {
		searchhouselist,
		getUserLevelcode,
		houseinfolist,
		searchHouseNumsTree,
		houseLabelTree,
		searchHouseNumsTreeByItem,
		indexPeronRelation,
		personInfo,
		exportHouseList
	} from '@/api/shaicha'
	export default {
		data() {
			return {
				exportLoading: false,
				xiangqing3:false,
				selectleixing:'',
				chaxun: {
					name: '',
					phone: '',
					xingzhi: '',
					carnum: '',
					bikenum: '',
					dayu: '',
					xiaoyu: '',
					address: ''
				},
				xiangqing2:false,
				housemsg: {},
				masterlist: [],
				personlist: [],
				xiangqing: false,
				showpicpage:{
					housepersonlable:[],
					hushu:'',
					huzairenbujingchangzai:'',
					huzairenbuzai:'',
					huzairenzai:'',
					liudongrenkou:'',
					personinfolist:[],
					personnums:'',
					liudonghushu:''
				},
				loadingHouse: false,
				showType: 1,
				list: [],
				time: new Date(),
				treeId: '',
				searchName: '',
				search: '',
				dialogTableVisible: false,
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [8, 10, 20, 30, 40],
					pagesize: 8,
					totalnum: 0
				},
				form: {
					name: '',
					status: '',
					remark: ''
				},
				defaultProps: {
					children: 'list',
					label: 'name'
				},
				treeSelectId: '',
				tree: [],
				newbutton: false,
				levelcode: '',
				xiugaiVisible: false,
				form2: {
					level: '',
					name: '',
					type: '',
					remark: '',
					id: ''
				},
				xiugaiLevelcode: '',
				gridid: '',
				loading1: true,
				loading2: true,
				loading3: true,
				mapVisible: false,
				form3: {
					netLocation: '',
					centerLocation: ''
				},
				house_code: '',
				lableTreeData: [],
				labelTreeProps: {
					label: 'labelName',
					children: 'children'
				},
				activeName: 'community',
				itemId: null,
				labelId: null,
				peoplelist:[],
				selectname:'',
				selectTagName: '',
				historyData:[],
				jichu: {
					mulpersoninfp: {
						personname: '',
						person_sex: '',
						personcard: '',
						houseareatemp: '',
						censusaddr: '',
						personphone: '',
						deldesc: '',
						communityname: '',
						regionname: '',
						party: '',
						housearea:'',
					},
					labelinfo:[],
					personLabel: [{
						name: '',
						labellist: [{
							history: false,
							labelname: ''
						}]
					}]
				},
			};
		},
		mounted() {
			let that = this;
			that.getTree();
			that.getList();
			getUserLevelcode({
				'x-access-token': that.GLOBAL.token(),
				userid: that.GLOBAL.adminId()
			}).then((res) => {
				if (res.result == '200') {
					that.list = res.detail;
				} else {
					that.$message.error(res.description);
				}
			})
		},
		methods: {
			handleClose3: function(done) {
				this.historyData = [];
				this.jichu = {
					mulpersoninfp: {
						personname: '',
						person_sex: '',
						personcard: '',
						houseareatemp: '',
						censusaddr: '',
						personphone: '',
						deldesc: '',
						communityname: '',
						regionname: '',
						party: ''
					},
					personLabel: [{
						name: '',
						labellist: [{
							history: false,
							labelname: ''
						}]
					}]
				};
				done();
			},
			highTag(tagname){
				console.log(tagname)
				if(this.selectTagName == tagname){
					this.selectTagName = '';
				}else{
					this.selectTagName = tagname;
				}
				
			},
			xuanze(a){
				let that = this;
				if(that.selectleixing === ''){
					that.selectleixing = a;
				}else{
					that.selectleixing = '';
				}
			},
			relation(a) {
				let that = this;
				if(a){
					that.xiangqing2 = true;
					indexPeronRelation({
						'x-access-token': that.GLOBAL.token(),
						"userid": that.GLOBAL.adminId(),
						'housecode': a
					}).then((res) => {
						if (res.result == '200') {
							let detail = res.detail;
							let personlist = detail ? detail.personlist : [];
							that.peoplelist = detail ? detail.infoliet : [];
							let guanxilist = detail ? detail.guanxilist : [];
							if (detail) {
								that.openechart(personlist, guanxilist);
							} else {
								that.openechart([], []);
								that.$message.warning('当前暂无关系数据');
							}
						} else {
							that.$message.error(res.description);
						}
					})
				}else{
					that.$message.warning('暂无数据');
				}
				
			},
			openechart(list1, list2) {
				let that = this;
				let randomArr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e'];
				let myChart = echarts.init(document.getElementById('box'));
				let personlist = [];
				let guanxilist = [];
				// let categories = [{
				// 	name: 'person',
				// 	//symbol: 'rect',
				// 	//symbol: 'circle',
				// 	// symbol: 'roundRect',
				// 	// symbol: 'triangle',
				// 	// symbol: 'pin',
				// 	// symbol: 'arrow',
				// 	symbol: 'circle',
				// 	itemStyle: {
				// 		normal: {
				// 			color: "#4592FF",
				// 		}
				// 	},
			
				// }];
				let categories = [];
				let renlist = JSON.parse(JSON.stringify(list1));
				let unique = (arr) => {
					for (let i = 0; i < arr.length; i++) {
						for (let j = i + 1; j < arr.length; j++) {
							if (arr[i].accountcode == arr[j].accountcode) {
								arr.splice(j, 1)
								j--
							}
						}
					}
					return arr
				}
				let typelist = unique(renlist);
				for (let i = 0; i < list1.length; i++) {
					for (let j = 0; j < typelist.length; j++) {
						if (list1[i].accountcode == typelist[j].accountcode) {
							list1[i].type = j + '' + list1[i].incommunity;
						}
					}
				}
				for (let i = 0; i < typelist.length; i++) {
					let color = '';
					for (var j = 0; j < 6; j++) {
						color += randomArr[Math.ceil(Math.random() * (14 - 0) + 0)];
					}
					color = '#' + color;
					categories.push({
						name: i + '0',
						symbol: 'circle',
						itemStyle: {
							normal: {
								color: color
							}
						},
					});
					categories.push({
						name: i + '1',
						symbol: 'rect',
						itemStyle: {
							normal: {
								color: color
							}
						},
					});
					categories.push({
						name: i + '2',
						symbol: 'triangle',
						itemStyle: {
							normal: {
								color: color
							}
						},
					});
					categories.push({
						name: i + '3',
						symbol: 'roundRect',
						itemStyle: {
							normal: {
								color: color
							}
						},
					});
				}
			
				for (let item of list1) {
					personlist.push({
						name: item.showname, //节点名称
						category: item.type, //节点类型
						draggable: true, //节点是否可拖动
						detail: item
					})
				}
				for (let item of list2) {
					guanxilist.push({
						source: item[1], //关系的起点
						target: item[0], //关系的终点
						value: item[2]
					})
				}
				// 绘制图表
				let option = {
					// title: {
					// 	text: '项目经费',
					// 	top: '12%',
					// 	left: 'center'
					// },
					label: {
						normal: {
							show: true
						}
					},
					series: [{
						edgeSymbol: ["circle", "arrow"],
						edgeSymbolSize: 8,
						edgeLabel: {
							normal: {
								show: true,
								textStyle: {
									fontSize: 12,
								},
								formatter: function(params) {
			
									return params.data.value;
								},
							},
						},
						type: 'graph', //关系图
						layout: 'force', //力导向图的布局
						symbolSize: 50, //节点大小
						// focusNodeAdjacency: true, //鼠标悬停在节点上时，会隐藏和当前节点非直接连接的节点
						roam: true,
						categories: categories,
						label: { //节点名称
							normal: {
								show: true,
								textStyle: {
									fontSize: 12 //节点名称显示大小
								},
							}
						},
						force: {
							repulsion: 600 //节点之间的排斥力
						},
						data: personlist,
						links: guanxilist,
						lineStyle: { //关系连接线的样式设置
							normal: {
								opacity: 0.9, //关系连接线的不透明度为0.9
								width: 3, //关系连接线的宽度
								curveness: 0 //关系连接线的弯曲程度
							}
						}
					}]
				};
				myChart.setOption(option);
			},
			handleClose2: function(done) {
				this.selectname = '';
				this.peoplelist = [];
				done();
			},
			//查看
			lookHouseInManClick(a) {
				var that = this;
				that.loadingHouse = true;
				houseinfolist({
					'x-access-token': that.GLOBAL.token(),
					"userid": that.GLOBAL.adminId(),
					'houseid': a,
					'page': 1,
					'pagesize': ''
				}).then((res) => {
					if (res.result == '200') {
						that.loadingHouse = false;
						that.housemsg = res.detail.houselist.list[0];
						that.masterlist = res.detail.masterlist;
						that.personlist = res.detail.personlist;
						
						that.showpicpage = res.detail.showpicpage;
						that.xiangqing = true;
					} else {
						that.$message.error(res.description);
					}
				})

			},
			lookClick(a) {
				var that = this;
				personInfo({
					'x-access-token': that.GLOBAL.token(),
					"userid": that.GLOBAL.adminId(),
					queryid: a,
				}).then((res) => {
					if (res.result == '200') {
						that.jichu = res.detail;
						that.xiangqing3 = true;
					} else {
						that.$message.error(res.description);
					}
				})

			},
			handleClose: function(done) {
				this.xiangqing = false;
				this.housemsg = {};
				done();
			},
			// nodeClick() {
			// 	let node = this.$refs.tree.getCurrentNode()
			// 	this.$emit("treeClick", node.name, node.id)
			// },
			getTree() {
				this.loading1 = true;
				searchHouseNumsTree({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId()
				}).then((res) => {
					this.loading1 = false;
					if (res.result == '200') {
						let tree = res.detail.tree;
						for (let item1 of tree) {
							let num1 = item1.showpersonnums == undefined ? '' : '(' + item1.showpersonnums + ')';
							item1.name += num1;
							item1.list = item1.regionlist;
							for (let item2 of item1.list) {
								let num2 = item2.showpersonnums == undefined ? '' : '(' + item2.showpersonnums +
									')';
								item2.name = item2.regionname + num2;
								item2.list = item2.buildlist;
								for (let item3 of item2.list) {
									let num3 = item3.showpersonnums == undefined ? '' : '(' + item3
										.showpersonnums + ')';
									item3.name = item3.buildname + num3;
									if (item3.housedanyuan) {
										item3.list = item3.housedanyuan;
										for (let item4 of item3.list) {
											let num4 = item4.showpersonnums == undefined ? '' : '(' + item4
												.showpersonnums + ')';
											item4.name = item4.housenum + num4;

										}
									}

								}
							}
						}
						this.tree = tree;
					} else {
						this.$message.error(res.description);
					}
				})

				//标签树
				houseLabelTree({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == '200') {
						// console.log(res)
						let treeData = res.detail;
						treeData.map(item => {
							item.labelName = item.typeName + `(${item.personCount})`;
							item.children.map(child => {
								child.labelName = child.labelName + `(${child.personCount})`;
							})
						})
						this.lableTreeData = treeData;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			nodeClick(data) {
				// console.log(data);
				var that = this;
				that.tableData = [];
				that.currentPage = 1;
				that.house_code = data.house_code;
				that.getList();
			},
			//展示时要给第一、二标签赋值
			expandClick(data, node) {
				if (node.level == 1) {
					this.labelId = data.id;
					this.itemId = null;
					this.house_code = null;
				} else if (node.level == 2) {
					this.itemId = data.id;
					this.house_code = null;
				}
			},
			//标签树点击
			lableTreeNodeClick(data, node) {
				console.log(data)
				if (node.level == 1) {
					this.labelId = data.id;
					this.itemId = null;
					this.house_code = null;
				} else if (node.level == 2) {
					this.itemId = data.id;
					this.house_code = null;
				} else {
					this.house_code = data.house_code;
				}

				this.tableData = [];
				this.currentPage = 1;
				this.getList();
			},
			//异步加载树结构
			loadNode(node, resolve) {
				if (node.level == 1) {
					return resolve(node.data.children);
				}
				// 其余节点处理
				if (node.level == 2) {
					this.getSecondTreeList(node.data, resolve);
				}
				if (node.level >= 3) {
					if (node.data.children) {
						return resolve(node.data.children)
					} else {
						return resolve([])
					}
				}
			},
			//点击标签数
			getSecondTreeList(data, resolve) {
				// console.log(data)
				searchHouseNumsTreeByItem({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					itemid: data.id,
				}).then((res) => {
					if (res.result == '200') {
						let tree = res.detail.tree;
						// console.log(tree)
						for (let item1 of tree) {
							let num1 = item1.showpersonnums == undefined ? '' : '(' + item1.showpersonnums + ')';
							item1.labelName = item1.name + num1;
							item1.children = item1.regionlist;
							for (let item2 of item1.children) {
								let num2 = item2.showpersonnums == undefined ? '' : '(' + item2.showpersonnums +
									')';
								item2.labelName = item2.regionname + num2;
								item2.children = item2.buildlist;
								for (let item3 of item2.children) {
									let num3 = item3.showpersonnums == undefined ? '' : '(' + item3
										.showpersonnums + ')';
									item3.labelName = item3.buildname + num3;
									if (item3.housedanyuan) {
										item3.children = item3.housedanyuan;
										for (let item4 of item3.children) {
											let num4 = item4.showpersonnums == undefined ? '' : '(' + item4
												.showpersonnums + ')';
											item4.labelName = item4.housenum + num4;
										}
									}
								}
							}
						}
						// console.log(tree)
						return resolve(tree);
					} else {
						this.$message.error(res.description);
					}
				})

			},
			gotoGui(){
				this.$router.push({
					name: 'fangwugui'
				})
			},
			onsearch() {
				this.currentPage = 1;
				this.search = this.searchName;
				this.getList();
			},
			getList() {
				var that = this;
				that.loading2 = true;
				searchhouselist({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					'levelcode': this.chaxun.shequ,
					'mastername': this.chaxun.name,
					'phone': this.chaxun.phone,
					'housetype': this.chaxun.xingzhi,
					'carnum': this.chaxun.carnum,
					'bikenum': this.chaxun.bikenum,
					'morepeople': this.chaxun.dayu,
					'lesspeople': this.chaxun.xiaoyu,
					'houseaddress': this.chaxun.address,
					'houseid': '',
					'house_code': this.house_code,
					page: that.currentPage,
					pagesize: that.fenye.pagesize,
					labelid: that.activeName == 'label' ? that.labelId : null,
					itemid: that.activeName == 'label' ? that.itemId : null,
				}).then((res) => {
					that.loading2 = false;
					if (res.result == '200') {
						that.tableData = res.detail.houselist.list;
						that.fenye.totalnum = res.detail.houselist.totalRow;
					} else {
						that.$message.error(res.description);
					}
				})
			},
			//导出列表
			exportList(){
				this.exportLoading = true;
				exportHouseList({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					'levelcode': this.chaxun.shequ,
					'mastername': this.chaxun.name,
					'phone': this.chaxun.phone,
					'housetype': this.chaxun.xingzhi,
					'carnum': this.chaxun.carnum,
					'bikenum': this.chaxun.bikenum,
					'morepeople': this.chaxun.dayu,
					'lesspeople': this.chaxun.xiaoyu,
					'houseaddress': this.chaxun.address,
					'houseid': '',
					'house_code': this.house_code,
					labelid: this.activeName == 'label' ? this.labelId : null,
					itemid: this.activeName == 'label' ? this.itemId : null,
				}).then((res) => {
					this.exportLoading = false;
					if (res.result == '200') {
						console.log(res);
            FileSaver.saveAs(res.detail, res.detail.split('/downfile/')[1]);
					} else {
						this.$message.error(res.description);
					}
				}).catch(err=>{
					this.exportLoading = false;
					this.$message.error(err);
				})
			},
			getTextOfVal(val){
				if(val === 0){
					return '户在人在'
				}else if(val === 1){
					return '户在人不经常在'
				}else if(val === 2){
					return '流动人口'
				}else if(val === 3){
					return '户在人不在'
				}else{
					return '/'
				}
			},
		}
	};
</script>

<style lang="scss" scoped="scoped">
	.create p {
		font-size: 18px;
		color: #1e9fff;
		// margin: 3px 0;
	}
	.produce {
		overflow: hidden;
		box-sizing: border-box;
		border-top: 1px solid #666;
		border-left: 1px solid #666;
		margin-top: 20px;
		.biaoqian{
			width: 100%;
			height: auto !important;
			padding: 5px 10px;
			ul{
				display: flex;
				flex-wrap: wrap;
				li{
					margin: 2px 4px 2px 0;
					color: #2a8cf1;
					border: 1px solid #2a8cf1;
					border-radius: 100px;
					padding: 2px 8px;
					line-height: 1.2em;
					font-size: 14px;
				}
			}
			
		}
	}
	
	.produce>div {
		float: left;
		width: 50%;
		height: 41px;
		box-sizing: border-box;
		border-bottom: 1px solid #666;
		border-right: 1px solid #666;
		line-height: 40px;
		padding: 0 10px;
	}
	.relationbox{
		width: 45%;
		height: 600px;
		overflow: auto;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		justify-content: space-between;
		align-items: center;
		align-content: flex-start;
		.relationlist {
			position: relative;
			width: 50%;
			padding: 0 10px 0 30px;
			box-sizing: border-box;
			// height: 300px !important;
			// float: left;
			.headsex {
				width: 60px;
				height: 60px;
				text-align: center;
				line-height: 60px;
				position: absolute;
				left: 0;
				top: 20px;
				background-color: #f0f0f0;
				border-radius: 50%;
				.iconsex {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
		
					img {
						height: 80%;
						width: auto;
					}
				}
			}
		
			.xiangxi {
				margin: 5px 0;
				background-color: #fff;
				border: 1px solid #119CF0;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 40px 0;
				box-sizing: border-box;
				p {
					line-height: 1.6em;
					font-size: 14px;
					display: block;
					box-sizing: border-box;
					padding-right: 20px;
					width: 100%;
					span {
						width: 100px;
						text-align: right;
						display: inline-block;
					}
				}
				.biaoqian{
					width: 100%;
					height: auto !important;
					padding: 0 20px;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					box-sizing: border-box;
					.name{
						width: 100px;
						text-align: right;
						line-height: 1.2em;
						font-size: 14px;
					}
					.tag{
						margin: 2px 4px 2px 0;
						color: #2a8cf1;
						border: 1px solid #2a8cf1;
						border-radius: 100px;
						padding: 2px 8px;
						line-height: 1.2em;
						font-size: 14px;
					}
					
				}
			}
			.selected{
				border: 3px solid red !important;
			}
		}
	}
	.personsbox {
		display: flex;

		.huzhuinfo {
			width: 36%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			&::after,
			&::before {
				position: absolute;
				content: '';
				width: 0;
				height: 0;
				border: 10px solid #fff;
				border-right-color: #119CF0;
				right: -1px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 5;
			}

			&::before {
				border: 8px solid transparent;
				border-right-color: #fff;
				z-index: 10;
			}
		}

		.fz_item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.head {
				margin-bottom: 40px;
				width: 90px;
				height: 90px;
				text-align: center;
				line-height: 90px;
				position: relative;
				background-color: #f0f0f0;
				border-radius: 50%;

				.iconsex {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 80%;
						width: auto;
					}
				}

				i {
					font-size: 50px;
					line-height: 90px;
				}

				>img {
					position: absolute;
					bottom: -20px;
					left: 50%;
					transform: translateX(-50%);
					width: auto;
					height: 40px;
				}

				&+div {
					font-size: 14px;
					color: #333;
				}
			}

			ul {
				li {
					line-height: 1.6em;

					span {
						width: 90px;
						text-align: right;
						display: inline-block;
					}
				}
			}
		}

		.zhuhuinfo {
			margin-top: 20px;
			padding: 20px 0 20px 100px;
			border-left: 1px solid #119CF0;
			position: relative;

			&::before,
			&::after {
				width: 50px;
				height: 1px;
				content: "";
				background-color: #119CF0;
				position: absolute;
				left: 0;
			}

			&::before {
				top: 0;
			}

			&::after {
				bottom: 0;
			}

			.zh_item {
				position: relative;

				&:last-child {
					border-bottom: 0 none;
				}

				.head {
					width: 60px;
					height: 60px;
					text-align: center;
					line-height: 60px;
					position: absolute;
					left: - 20px;
					top: 50%;
					transform: translateY(-90%);
					background-color: #f0f0f0;
					border-radius: 50%;

					.iconsex {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							height: 80%;
							width: auto;
						}
					}

					i {
						font-size: 20px;
						line-height: 60px;
					}

					p {
						position: absolute;
						font-size: 14px;
						width: 80px;
						text-align: center;
						color: #333;
						left: 50%;
						line-height: 1.6em;
						border: 1px solid #119CF0;
						transform: translateX(-50%);
						bottom: -30px;
						background-color: #fff;
						border-radius: 6px;
					}
				}

				ul {
					margin: 20px 0;
					padding: 25px 50px 25px 30px;
					background-color: #fff;
					border: 1px solid #119CF0;

					li {
						line-height: 1.6em;
						font-size: 14px;

						span {
							width: 120px;
							text-align: right;
							display: inline-block;
						}
					}
				}
			}


		}
	}
	.tishi{
		position: absolute;
		top: 0;
		left: 15px;
		font-size: 15px;
		height: 30px;
		line-height: 30px;
		.type2{
			padding-left: 30px;
			background-size: 24px 24px;
			background-repeat: no-repeat;
			background-position: 0px 3px;
		}
		.circle{
			background-image: url(../../assets/imgs/circle.png);
		}
		.rect{
			background-image: url(../../assets/imgs/rect.png);
		}
		.triangle{
			background-image: url(../../assets/imgs/triangle.png);
		}
		.roundrect{
			background-image: url(../../assets/imgs/roundrect.png);
		}
	}
	.block {
		text-align: right;
	}

	.right .transformer {
		box-sizing: border-box;
		height: 100%;
		overflow-y: auto;
	}

	.topbox {
		height: 167px;
		margin-bottom: 28px;
	}

	.home {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		background: #fff;

		.left {
			width: 320px;
			height: 100%;
			overflow: auto;
			box-sizing: border-box;
			// border: solid 1px #d7dde4;
			float: left;

			.search {
				width: 100%;
				box-sizing: border-box;
				padding: 16px;
				border-bottom: solid 1px #d7dde4;
			}

			& /deep/ .el-tree {
				.el-tree-node__content {
					height: 32px;
				}

				.el-icon-caret-right {
					width: 14px;
					text-align: center;
					padding-left: 16px;
					padding-right: 8px;
				}

				.el-icon-caret-right:not(.expanded):before {
					content: '+';
					font-size: 18px;
					font-weight: bold;
					transform: none;
					color: #848694;
				}

				.el-tree-node__expand-icon.expanded {
					transform: rotate(0deg);
				}

				.el-tree-node__expand-icon.expanded:before {
					content: '-';
					font-size: 18px;
					font-weight: bold;
					color: #848694;
				}

				.is-leaf {
					opacity: 0;
				}

				.my-custom {
					font-size: 14px;

					& * {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.right {
			width: calc(100% - 320px);
			height: 100%;
			box-sizing: border-box;
			padding: 0 16px;
			float: left;
		}
	}



	.form3style {
		padding: 0 20px;

		.el-form-item {
			.spanstyle {
				padding-left: 25px;
				font-size: 20px;
				background: url(../../assets/imgs/edit.png) no-repeat left center;
				background-size: 20px auto;
				color: #5B93C1;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.detailtable {
		overflow: hidden;
		border-top: 1px solid #e3e4e3;
		border-left: 1px solid #e3e4e3;
		margin-bottom: 20px;
		.biaoqian{
			width: 100%;
			height: auto !important;
			padding: 5px 10px;
			ul{
				display: flex;
				flex-wrap: wrap;
				li{
					margin: 2px 4px 2px 0;
					color: #2a8cf1;
					border: 1px solid #2a8cf1;
					border-radius: 100px;
					padding: 2px 8px;
					line-height: 1.2em;
					font-size: 14px;
				}
			}
			
		}
	}

	.detailtable p,
	.detailtable div {
		float: left;
		box-sizing: border-box;
		border-right: 1px solid #e3e4e3;
		border-bottom: 1px solid #e3e4e3;

	}

	.detailtable p {
		height: 37px;
		line-height: 37px;
		background-color: #e1f3fd;
		width: 100%;
		margin: 0;
		padding-left: 8px;
		color: #5b93c1;
		font-size: 16px;

	}

	.detailtable div {
		height: 36px;
		line-height: 36px;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: 0 10px;
	}

	.detailtable .labelname {
		width: 8%;
	}

	.detailtable .biaoshi {
		text-align: left;

	}
	.allpeople {
		overflow: hidden;
		box-sizing: border-box;
		border: 1px solid #e8e9e8;
		padding: 0 22px;
	
		>div:first-child {
			border-top: none !important;
		}
	
		.yihu {
			border-top: 1px solid #d2d2d2;
			padding: 28px 0 0;
			position: relative;
			padding-left: 120px;
			display: flex;
			flex-wrap: wrap;
	
			.title {
				text-align: center;
				font-size: 12px;
				color: #fff;
				position: absolute;
				left: 0;
				top: 50%; 
				transform: translateY(-50%);
				width: 90px;
				height: 90px;
				img{
					width: 50px;
					height: auto;
				}
				p{
					margin-top: 10px;
					color: #333;
				}
			}
			>.people:first-child {
				border-left: none !important;
			}
			.people {
				border-left: 1px dotted #e5e5e5;
				padding: 0 20px;
				margin-bottom: 28px;
				
				.head {
					display: inline-flex;
					flex-wrap: wrap;
					writing-mode: vertical-lr;
					position: relative;
					
					.img{
						overflow: hidden;
						width: 90px;
						height: 90px;
						margin: 0 10px;
						border-radius: 50%;
						cursor: pointer;
						img {
							display: block;
							width: 100%;
							height: auto;
						}
					}
					.huise{
						background-color: #e8e9e8;
					}
					.lanse{
						background-color: #259cef;
					}
					p {
						line-height: 28px;
						text-align: center;
						font-size: 16px;
						color: #878787;
						width: 96px;
						writing-mode: horizontal-tb;
					}
				
					.tag {
						box-sizing: border-box;
						writing-mode: horizontal-tb;
						border-radius: 200px;
						margin: 2px 5px;
						border: 1px solid #666;
						height: 20px;
						line-height: 18px;
						padding: 0 5px;
						background-color: #fff;
						color: #666;
						font-size: 14px;
						position: absolute;
						right: 0;
						transform: translateX(60%);
						top: -10px;
						&.blue{
							background-color: #409eff;
							border: 1px solid #409eff;
							color: #fff;

						}
					}
				}
			}
		}
	}
	.alldata{
		.hang{
			display: flex;
			flex-wrap: wrap;
			div{
				margin: 0 18px 10px 0;
				height: 30px;
				line-height: 30px;
				border-radius: 500px;
				padding: 0 15px;
				font-size: 16px;
			}
			
		}
		.one{
			div{
				background-color: #00a0e9;
				color: #fff;
			}
		}
		.two{
			div{
				background-color: #e5f3ff;
				color: #48a8d7;
				cursor: pointer;
			}
			.selected{
				background-color: #00a0e9;
				color: #fff;
			}
		}
		.three{
			display: flex;
			justify-content: end;
			cursor: pointer;
			div{
				box-sizing: border-box;
				border: 1px solid #666;
				background-color: #fff;
				color: #666;
				line-height: 28px !important;
			}
			.highblue{
				background-color: #00a0e9;
				color: #fff;
			}
		}
	}
</style>